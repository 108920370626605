/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllOrg = payload => {
  return instance.instanceToken
    .get("/organiser/all", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};

const SetDelete = payload => {
  return instance.instanceToken
    .post("/organiser/delete", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const SetRFPCredit = payload => {
  return instance.instance
    .get(`/organiser/addRfp/${payload.id}/${payload.credit}`)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const UpdateAccount = payload => {
  return instance.instanceToken
    .post("/organiser/admin_update", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
export default {
  //   logIn: LogIn,
  getAllOrg: GetAllOrg,
  setDelete: SetDelete,
  setRFPCredit: SetRFPCredit,
  updateAccount: UpdateAccount
};
